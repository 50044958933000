@import "_index.less";

main {
    > section {
    	box-shadow: none;
    	>.carousel {
    		margin-bottom: 25px;
    	}
        >.container {
		    position: relative;
		    z-index: 2;
            .breadcrumb {
            	
            }
        }
    }
}