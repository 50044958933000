@import "../../../../public/less/template/variables.less";

.main {
    >.main-section {
        >.section-center {
            >.block-page {
                .page-header {
                    .block-circle {
                        position: relative;
                        background: #fff;
                        box-shadow: 0px 3px 0 0 #FC0;
                        width: 100px;
                        height: 70px;
                        border-radius: 50px;
                        margin-top: -30px;
                        >h2 {
                            position: absolute;
                            left: 0;
                            top: 30px;
                            font-size: large;
                            font-weight: bolder;
                            text-transform: uppercase;
                            width: 100%;
                            margin: 0;
                            padding: 0;
                        }
                        >h3 {
                            position: absolute;
                            left: 0;
                            top: 50px;
                            font-size: x-small;
                            font-weight: lighter;
                            text-transform: uppercase;
                            width: 100%;
                            margin: 0;
                            padding: 0;
                        }
                    }
                }
                .search-by-price {
                	>.list-group-item {
                		border-color: @colorSecondary;
                		>.badge {
            			    margin-top: -10px;
            			    width: 43px;
            			    height: 43px;
            			    line-height: 43px;
            			    font-size: 20px;
            			    color: @colorPrimary;
            			    background-color: @colorSecondary;
            			    border-radius: 0;
                		}
                	}
                }
            }
            .block-actualities,
            >section.section-actualities {
                .link-actualities {
                    position: absolute;
                    right: 25px;
                    top: 21px;
                    color: #fff;
                    z-index: 1;
                    line-height: 25px;
                }
                .thumbnail-actuality {
                    .caption {
                        h3 {
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                            font-size: medium;
                            font-weight: 600;
                        }
                        p {
                            font-size: medium;
                        }
                    }
                    border: none;
                    border-radius: 0;
                    padding: 0;
                    text-decoration: none;
                    .transition() ;
                    >.picture {
                        height: 190px;
                        max-height: 190px;
                    }
                    >.caption {
                        padding: 10px 0;
                        >.caption-date {
                            float: left;
                            border: 1px solid #ccc;
                            width: 54px;
                            text-align: center;
                            margin-right: 10px;
                            background: #fff;
                            .transition() ;
                            >.date-day {
                                font-size: x-large;
                                font-weight: bold; 
                            }
                            >.data-month {
                                font-size: small;
                                text-transform: uppercase;
                            }
                        }
                        >.caption-author {
                            font-size: small;
                            color: #002850;  
                        }
                        >.caption-title {
                            font-size: medium;
                            font-weight: 500;
                            line-height: 18px;
                            margin-bottom: 10px;
                            padding-bottom: 10px;
                            border-bottom: 1px solid #EEE;
                            overflow: hidden;
                            height: 37px;
                        }
                        >.caption-text {
                            font-size: small;
                            text-align: justify;
                            overflow: hidden;
                            height: 50px;
                        }
                    }
                    &:hover {
                        >.caption {
                            >.caption-date {
                                background: @colorPrimary;
                                color: @colorOnColorPrimary;
                            }
                        }
                    }
                }
            }
            .block-adverts,
            >section.section-adverts {
            	.card {
            	    position: relative;
            	    z-index: 1;
                    border-radius: 0;
                    margin: 0;
                    border: none;
                    margin-bottom: 1.5rem;
                    background-color: #eee;
                    box-shadow: 0px 0px 5px -1px #111111;
                    >.card-label {
                        position: absolute;
                        z-index: 2;
                        right: 1rem;
                        top: 1rem;
                        width: 3rem;
                        height: 2rem;
                        line-height: 2rem;
                        background-color: rgba(17, 17, 17, 0.7);
                        text-align: center;
                        font-weight: 700;
                    }
            		>img {
            		    border-radius: 0;
            		}
            		>.card-body {
            		    >.card-title {
                            color: #111;
                            font-size: 1rem;
                            text-align: center;
                            font-weight: 400;
            		    }
            		    >.card-text {
                            color: #111;
                            margin: 0;
                            text-align: center;
                            font-size: 0.8rem;
                            font-weight: 700;
            		    }
            		}
            		>.card-footer {
            		    border-radius: 0;
            		    background-color: #ddd;
            		    .transition();
            		    >.card-text {
            		        color: #111;
            		        margin: 0;
                            text-align: center;
                            font-size: 1.2rem;
            		    }
            		}
            		.transition();
            		&:hover {
            		    background-color: #fafafa;
            		    box-shadow: 0px 0px 5px 5px #f7931e;
            		    >.card-footer {
            		        background-color: #eee;
            		    }
            		}
            	}
            }
            .block-articles,
            >section.section-articles {
            	.thumbnail.article {
            	    padding: 0;
            	    border-radius: 0px;
            	    box-shadow: 0px 10px 2px -5px #c8c8c8, 0px 0px 0px 1px #c8c8c8;
            	    border: none;
            	    .transition();
            		>.caption {
            			margin: 0;
            			padding: 0;
            			&.header {
            				>h3 {
            					margin: 0;
            					padding: 5px;
            					text-align: center;
            					font-size: 12px;
            					color: @colorOnColorSecondary;
            					background: @colorSecondary;
            					border-radius: 4px 4px 0px 0px;
            					text-overflow: ellipsis;
            					white-space: nowrap;
            					overflow: hidden;
            					line-height: 20px;
            					&.promotion {
            						background: #FF0000;
            						color: #FFF;
            					}
            				}
            			}
            			&.middle {
            				>h3 {
            					margin: 0;
            					padding: 5px;
            					text-align: right;
            					font-size: small;
            					background: rgba(90,180,90,0.2);
            					color: rgb(0, 92, 0);
            					line-height: 0px;
            				}
            			}
            			&.footer {
            				height: 120px;
            				min-height: 120px;
            				position: relative;
            				>h3 {
            					padding: 10px;
            					text-align: left;
            					&.name {
            						margin: 0;
            						font-size: small;
            						color: #333;
            						line-height: 20px;
            						min-height: 50px;
            						height: 50px;
            					}
            					&.price {
            					    position: absolute;
            					    bottom: 10px;
            					    left: 0;
            					    right: 10px;
            						font-size: large;
            						color: @colorPrimary;
            						margin: 0;
            						text-align: right;
            						padding: 0px;
            						>.icon-promo {
            						    float: left;
            						    background: red;
            						    color: white;
            						    font-weight: bold;
            						    font-size: 20px;
            						    padding: 10px;
            						    border-radius: 0px 25px 25px 0px;
            						    border: 1px solid #ccc;
            						    border-left: none;
            						    .transition();
            						}
            						>.more-details {
            						    border: 1px solid #ccc;
            						    background: #f0f0f0;
            						    float: right;
            						    padding: 2px 10px;
            						    color: #333;
            						    text-transform: uppercase;	
            						    .transition();
            						}
            						>.best-price {
            						    font-size: 50%;
            						    color: #333;
            						    font-style: italic;
            						    >.true-price {
            							    text-decoration: line-through;
            							    font-weight: bold;
            						    }
            						}
            						>.price-intval {
            							font-size: 28px;
            							font-weight: bold;
            							color: red;	
            						}
            						>.price-floatval {
            							font-size: 14px;
            							color: red;	
            						}
            						>sup {
                                        top: -0.6em;
                                        font-size: 50%; 
            						}
            						>sub {
                                        bottom: -0.4em;
                                        font-size: 50%;	    
            						}
            						>small {
            							color: #000; 
            						}
            					}
            				} 
            				>.description {
            				    margin: -10px 90px 10px 10px;
            				    font-size: 12px;
            				    color: #555;
            				    height: 70px;
            				    overflow: auto;						
            				}
            			}
            		}
            		>.picture {
            			background-color: #ccc !important;
            		    background-repeat: no-repeat !important;
            		    background-position: center !important;
            		    background-size: contain !important;
            		    margin: 0;
            		    height:190px;
            		    display: table;
            		    width: 100%;
            		    .transition();
            		    >.icon-search {
            		    	border-radius: 100px;
            		    	opacity: 0;	
            			    display: table-cell;
            			    vertical-align: middle;
            			    text-align: center;
            			    color: #fff;
            			    font-size: 60px;
            			    background: rgba(0, 0, 0, 0.2);
            			    .transition();
            		    }
            		}
            		&:hover,
            		&:focus {
            	    	box-shadow: 0px 10px 2px -5px @colorPrimary, 0px 0px 0px 1px @colorPrimary;
            	    	>.caption {
            				&.footer {
            					>h3 {
            						&.name {
            						}
            						&.price {
            							>.icon-promo {
            							    border-color: @colorPrimary;
            							}
            							>.more-details {
            							    border: 1px solid @colorPrimary;
            							    background: #fff;
            							    color: @colorPrimary;
            							}
            						}
            					} 
            				}		
            	    	}
            	    	>.picture {
            			    >.icon-search {
            			    	border-radius: 0;
            			    	opacity: 1;	
            				    -moz-transform: scaleX(-1);
            				    -o-transform: scaleX(-1);
            				    -webkit-transform: scaleX(-1);
            				    transform: scaleX(-1);
            				    filter: FlipH;
            				    -ms-filter: "FlipH";
            			    }
            	    	}
            		}
            	}
            	.thumbnail.formation {
            		>.formation-picture {
            			width: 100%;
            			height: 145px;	
            		}
            		>.formation-caption {
            		    position: relative;
            		    right: 0;
            			>.caption-date {
            			    position: relative;
            			    top: -10px;
            				>span {
            				    position: absolute;
            				    top: 0px;
            				    background: #f0f0f0;
            				    font-size: 12px;
            				    color: #333;
            				    border-radius: 10px;
            					&.date-start {
            						left: 10px;
            						padding: 2px 10px 2px 10px;
            					}
            					&.date-end {
            						left: 110px;
            						padding: 2px 10px 2px 10px;
            					}
            				}
            			}
            			>.caption-text {
            				position: relative;
            				top: 10px;	
            				>.text-title {
            					margin: 0;
            					padding: 10px 0 0 10px;
            					color: #000;
            					font-weight: bold;
            					height: 70px;
            					max-height: 70px;
            				}
            				>ul.list-group {
            					margin: 10px 0 0 0;
            					>li.list-group-item {
            					    border-radius: 0;
            					    border: none;
            					    margin: 0;
            					    padding: 0 0 5px 10px;
            					    color: #666;
            					    >i.fa {
            							background: #76b727;
            							border-radius: 10px;
            							width: 20px;
            							height: 20px;
            							text-align: center;
            							line-height: 20px;
            							color: #fff;	
            					    }
            					}
            				}
            			}
            			>.caption-info {
            				margin: 15px 10px 0 10px;
            				border-top: 1px solid #76b727;
            				padding-top: 10px;
            				.info-price {
            					text-align: center;
            					line-height: 26px;
            					font-size: 18px;
            					color: #000;
            					font-weight: bold;		
            				}
            				button.btn {
            					font-weight: bold;
            					color: #fff;
            					background: #76b727;
            					border: none;
            					font-size: 10px;
            					text-transform: uppercase;
            				}
            			}
            		}
            		&:focus,
            		&:hover {
            			text-decoration: none !important;
            			border-color: #76b727;	
            		}
            	}
            	.thumbnail.service {
            
            	}
            	.card.article {
            	    padding: 0;
            	    border-radius: 0px;
            	    box-shadow: 0px 10px 2px -5px #c8c8c8, 0px 0px 0px 1px #c8c8c8;
            	    border: none;
            	    margin: 0 0 2rem 0;
            	    .transition();
            		>.card-image {
            			background-color: #ccc !important;
            		    background-repeat: no-repeat !important;
            		    background-position: center !important;
            		    background-size: contain !important;
            		    margin: 0;
            		    height:210px;
            		    display: table;
            		    width: 100%;
            		    .transition();
            		    >.icon-search {
            		    	border-radius: 100px;
            		    	opacity: 0;	
            			    display: table-cell;
            			    vertical-align: middle;
            			    text-align: center;
            			    color: #fff;
            			    font-size: 60px;
            			    background: rgba(0, 0, 0, 0.2);
            			    .transition();
            		    }
            		}
            		>.card-body {
            			.card-title {
            				color: #111;
            			}
            			.card-text {
            				color: #333;
            			}
            			.card-price {
            				margin: 0;
            			    text-align: right;
            			    border-top: 1px solid #aaa;
            			    margin-top: 1rem;
            			    padding-top: 1rem;
            				text-align: right;
            				>.icon-promo {
            				    float: left;
            				    background: red;
            				    color: white;
            				    font-weight: bold;
            				    font-size: 20px;
            				    padding: 10px;
            				    border-radius: 0px 25px 25px 0px;
            				    border: 1px solid #ccc;
            				    border-left: none;
            				    .transition();
            				}
            				>.more-details {
            				    border: 1px solid #ccc;
            				    background: #f0f0f0;
            				    float: right;
            				    padding: 2px 10px;
            				    color: #333;
            				    text-transform: uppercase;	
            				    .transition();
            				}
            				>.best-price {
            				    font-size: 50%;
            				    color: #333;
            				    font-style: italic;
            				    >.true-price {
            					    text-decoration: line-through;
            					    font-weight: bold;
            				    }
            				}
            				>.price-intval {
            					font-size: 28px;
            					font-weight: bold;
            				}
            				>.price-floatval {
            					font-size: 14px;
            				}
            				>sup {
                                top: -0.6em;
                                font-size: 50%; 
            				}
            				>sub {
                                bottom: -0.4em;
                                font-size: 50%;	    
            				}
            				>small {
            					color: #000; 
            				}
            			}
            		}
            		&:hover,
            		&:focus {
            	    	box-shadow: 0px 10px 2px -5px @colorPrimary, 0px 0px 0px 1px @colorPrimary;
            	    	>.card-image {
            			    >.icon-search {
            			    	border-radius: 0;
            			    	opacity: 1;	
            				    -moz-transform: scaleX(-1);
            				    -o-transform: scaleX(-1);
            				    -webkit-transform: scaleX(-1);
            				    transform: scaleX(-1);
            				    filter: FlipH;
            				    -ms-filter: "FlipH";
            			    }
            	    	}
            		}
            	}
            }
        }
    }
}
// REAL ESTATE :
.main {
    >.main-section {
        >.section-center {
    		.block-realestate,
    		.block-real-estate,
    		>section.section-real-estate {
    			.card {
    				&.card-thumbnail-classic {
    					
    				}
    				&.card-thumbnail-elegant {
    					
    				}
    				&.card-thumbnail-design {
						border: none;
					    border-radius: 0;
					    margin-bottom: 35px;
	    				>.card-img {
	    					position: relative;
	    					z-index: 1;
						    height: 250px;
						    background-position: center center;
						    background-size: 125%;
						    background-repeat: no-repeat;
							border: none;
						    border-radius: 0;
						    .transition(2s);
	    					>.card-mask {
								position: absolute;
							    z-index: 2;
							    top: 0;
							    left: 0;
							    right: 0;
							    bottom: 0;
							    background-color: rgba(0, 0, 0, 0%);
								text-align: center;
								line-height: 250px;
							    .transition();
	    						>i {
									font-size: 4rem;
								    color: #fff;
								    text-shadow: 0px 0px 1px #000;
								    opacity: 0;
								    .transition();
	    						}
	    					}
	    					>.card-price {
								position: absolute;
							    z-index: 3;
							    left: 1.25rem;
							    bottom: -20px;
							    padding: .0 0 0 1.5rem;
							    line-height: 40px;
							    font-size: 1.2rem;
							    width: 40%;
							    min-width: 150px;
							    border-radius: 25px;
	    					}
	    				}
	    				>.card-body {
						    padding: 2.5rem 1.25rem 1.25rem 1.25rem;
						    >.card-title,
						    >.card-text {
							    overflow: hidden;
							    -o-text-overflow: ellipsis;
							    text-overflow: ellipsis;
							    display: -webkit-box;
							    -webkit-box-orient: vertical;
						    }
	    					>.card-title {
	    						font-size: 1.2rem;
							    margin: 0;
							    padding: 0;
							    -webkit-line-clamp: 1;
	    					}
	    					>.card-subtitle {
	    						margin: 0.5rem 0 0 0;
	    						font-weight: 400;
	    						>i {
	    							
	    						}
	    						>span {
	    							
	    						}
	    					}
	    					>.card-text {
							    font-size: .9rem;
							    margin-top: 0.5rem;
							    -webkit-line-clamp: 3;
	    					}
	    				}
	    				>.card-footer {
						    border-radius: 0;
						    padding: 0;
						    >ul {
							    display: -webkit-box;
							    display: -ms-flexbox;
							    display: flex;
							    -webkit-box-pack: space-evenly;
							    -ms-flex-pack: space-evenly;
							    justify-content: space-evenly;
							    list-style: none;
							    margin: 0;
							    padding: 0;
		    					li.card-detail {
								    padding: .25rem 0;
								    text-align: center;
								    font-size: 1rem;
								    width: 33.33%;
								    display: table-cell;
								    vertical-align: middle;
		    						>i {
		    							
		    						}
		    						>span {
		    							
		    						}
		    					}
						    }
	    				}
	    				&:hover {
	    					>.card-img {
	    						background-size: 140%;
	    						cursor: pointer;
	    						>.card-mask {
	    							background-color: rgba(255,255,255,0.1);
	    							>i {
								    	opacity: 1;
	    							}
	    						}
	    					}
	    				}
    				}
				}
    		}
        }       
    }
}