.transition(@duration:all 333ms, @ease:ease-out) {
  -webkit-transition: @duration @ease;
  -moz-transition: @duration @ease;
  -o-transition: @duration @ease;
  transition: @duration @ease;
}
.opacity(@opacity: 0.5) {
  -moz-opacity: @opacity;
  -khtml-opacity: @opacity;
  -webkit-opacity: @opacity;
  opacity: @opacity;
  @opperc: @opacity * 100;
  -ms-filter: ~"progid:DXImageTransform.Microsoft.Alpha(opacity=@{opperc})";
  filter: ~"alpha(opacity=@{opperc})";
}

/*/////////////////////////////////////////////////////////////////////////*/

@import url('https://fonts.googleapis.com/css?family=Patrick+Hand');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i');

@fontFamily: 'Patrick Hand', cursive;
@fontFamilyOpenSans: 'Open Sans', sans-serif;

/*/////////////////////////////////////////////////////////////////////////*/

@host:'https://www.francas02.fr';
@host_cdn:'https://www.icms.ovh';

/*/////////////////////////////////////////////////////////////////////////*/

@fontWeightLight:400;
@fontWeightNormal:400;
@fontWeightBold:700;
@fontWeightExtraBold:700;

/*/////////////////////////////////////////////////////////////////////////*/

@colorPrimary:#ffcc00;
@colorOnColorPrimary:#e73125;
@colorSecondary:#e73125;
@colorOnColorSecondary:#ffcc00;

@colorBlue:#42afff;
@colorPurple:#bf017f;
@colorGreen:#76b727;
@colorOrange:#e73125;
@colorLightblue:#0090ca;
@colorViolet:#6563d2;
@colorGray:#6f6f6f;